export * as ageurLogo from './ageur.svg';
export * as avaxLogo from './avax.svg';
export * as btcLogo from './btc.svg';
export * as busdLogo from './busd.svg';
export * as crvusdLogo from './crvusd.svg';
export * as linkLogo from './link.svg';
export * as daiLogo from './dai.svg';
export * as dogLogo from './dog.svg';
export * as ethLogo from './eth.svg';
export * as fraxLogo from './frax.svg';
export * as ftmLogo from './ftm.svg';
export * as gmxLogo from './gmx.svg';
export * as h2oLogo from './h2o.svg';
export * as highLogo from './highstreet.svg';
export * as hyperjumpLogo from './hyperjump.svg';
export * as jewelLogo from './jewel.png';
export * as klayLogo from './klay.svg';
export * as l2daoLogo from './l2dao.svg';
export * as maticLogo from './matic.svg';
export * as movrLogo from './movr.svg';
export * as nethLogo from './neth.svg';
export * as newoLogo from './newo.svg';
export * as nfdLogo from './nfd.svg';
export * as noteLogo from './note.svg';
export * as nusdLogo from './nusd.svg';
export * as ohmLogo from './ohm.svg';
export * as pepeLogo from './pepe.svg';
export * as plsLogo from './pls.svg';
export * as sdtLogo from './sdt.svg';
export * as sfiLogo from './sfi.svg';
export * as solarbeamLogo from './solar.svg';
export * as susdLogo from './susd.svg';
export * as synapseLogo from './syn.svg';
export * as unidexLogo from './unidex.svg';
export * as vstaLogo from './vsta.svg';
export * as wbtcLogo from './wbtc.svg';
export * as wethLogo from './weth.svg';
export * as avwethLogo from './avweth.svg';
export * as mimLogo from './mim.svg';
export * as usdcLogo from './usdc.svg';
export * as usdtLogo from './usdt.svg';
export * as usdbLogo from './usdc.svg';
export * as fusdtLogo from './usdt.svg';
export * as sushiLogo from './sushi.svg';
