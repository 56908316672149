export * as arbitrumImg from './arbitrum.svg';
export * as auroraImg from './aurora.svg';
export * as avalancheImg from './avalanche.svg';
export * as baseImg from './base.svg';
export * as bobaImg from './boba.svg';
export * as bscImg from './bnb.svg';
export * as cantoImg from './canto.svg';
export * as cronosImg from './cronos.svg';
export * as dfkImg from './dfk.svg';
export * as dogechainImg from './dogechain.svg';
export * as ethImg from './ethereum.svg';
export * as fantomImg from './fantom.svg';
export * as harmonyImg from './harmony.svg';
export * as klaytnImg from './klaytn.svg';
export * as metisImg from './metis.svg';
export * as moonbeamImg from './moonbeam.svg';
export * as moonriverImg from './moonriver.svg';
export * as optimismImg from './optimism.svg';
export * as polygonImg from './polygon.svg';
export * as lineaImg from './linea.svg';
