import * as CHAINS from '../chains/master';
import { getAddress } from '@ethersproject/address';
export var WalletId;
(function (WalletId) {
    WalletId["MetaMask"] = "metaMask";
    WalletId["WalletConnect"] = "walletConnect";
    WalletId["CoinbaseWallet"] = "coinbaseWallet";
})(WalletId || (WalletId = {}));
/**
 * Represents an ERC20-like token with a unique address, chainId, and some metadata.
 */
export class Token {
    constructor({ addresses, wrapperAddresses, decimals, symbol, name, logo, poolName, swapAddresses, swapWrapperAddresses, swapDepositAddresses, swapEthAddresses, routerIndex, poolId, poolType, poolTokens, depositTokens, nativeTokens, description, coingeckoId, docUrl = '', forceMeta, swapableType, isNative = false, swapExceptions, visibilityRank, isMeta, isEthSwap, category, swapableOn, display, legacy, priorityRank, chainId, incentivized, customRewardToken, miniChefAddress, priorityPool, color, priceUnits, notStake, routeSymbol, }) {
        this.decimals = {}; // list of decimals on each chain
        this.poolId = {}; // list of pool ids on each chain
        this.docUrl = ''; // token doc url
        this.isNative = false; // is native
        this.swapExceptions = {}; // for specifying tokens where limited dest chains are available.
        this.visibilityRank = 0; // rank in which token is displayed, least visible is 0, there is no max
        this.isMeta = false; // is meta
        this.isEthSwap = false; // is eth swap
        this.category = {
            bridge: true,
            swap: true,
            pool: true,
        }; // list of categories on each chain
        this.swapableOn = []; // list of chains where token is swapable
        this.display = true; // display token
        this.legacy = false; // legacy token
        this.priorityPool = false; // priority pool
        const isMetaVar = Boolean(swapDepositAddresses || forceMeta);
        this.addresses = validateAddresses(addresses);
        this.wrapperAddresses = wrapperAddresses;
        // this.decimals             = decimals
        this.decimals = makeMultiChainObj(decimals);
        this.symbol = symbol;
        this.name = name;
        this.icon = logo;
        this.poolName = poolName;
        this.swapAddresses = swapAddresses;
        this.swapWrapperAddresses = swapWrapperAddresses;
        this.swapDepositAddresses = swapDepositAddresses;
        this.swapEthAddresses = swapEthAddresses;
        this.routerIndex = routerIndex;
        this.poolTokens = poolTokens;
        this.nativeTokens = nativeTokens ?? poolTokens;
        this.depositTokens = depositTokens ?? this.nativeTokens;
        this.description = description;
        this.coingeckoId = coingeckoId;
        this.docUrl = docUrl ?? '';
        this.poolId = makeMultiChainObj(poolId);
        this.poolType = poolType;
        this.visibilityRank = visibilityRank ?? 0;
        this.isMeta = isMeta ?? false;
        this.isEthSwap = swapEthAddresses ? true : false;
        this.isNative = isNative ?? false;
        this.swapableType = swapableType;
        this.swapExceptions = swapExceptions ?? [];
        this.category = category ?? { bridge: true, swap: true, pool: true };
        this.swapableOn = swapableOn ?? [];
        this.display = display ?? true;
        this.legacy = legacy ?? false;
        this.priorityRank = priorityRank;
        this.chainId = chainId;
        this.incentivized = incentivized;
        this.customRewardToken = customRewardToken;
        this.miniChefAddress = miniChefAddress ?? '';
        this.priorityPool = priorityPool ?? false;
        this.color = color ?? 'gray';
        this.priceUnits = priceUnits ?? 'USD';
        this.notStake = notStake ?? false;
        this.routeSymbol = routeSymbol;
    }
}
const makeMultiChainObj = (valOrObj) => {
    if (typeof valOrObj === 'object') {
        return valOrObj;
    }
    else {
        const obj = {};
        for (const chain of Object.values(CHAINS)) {
            obj[chain.id] = valOrObj;
        }
        return obj;
    }
};
const validateAddresses = (addresses) => {
    const reformatted = {};
    for (const chainId in addresses) {
        reformatted[chainId] = addresses[chainId]
            ? getAddress(addresses[chainId])
            : '';
    }
    return reformatted;
};
