export * as ethExplorerImg from './etherscan.svg';
export * as arbitrumExplorerImg from './arbitrum.svg';
export * as bnbExplorerImg from './bscscan.svg';
export * as avalancheExplorerImg from './avalanche.svg';
export * as cantoExplorerImg from './canto.svg';
export * as optimismExplorerImg from './optimism.svg';
export * as polygonExplorerImg from './polygon.svg';
export * as dfkExplorerImg from './dfk-chain.svg';
export * as klaytynExplorerImg from './klaytn.svg';
export * as fantomExplorerImg from './fantom.svg';
export * as cronosExplorerImg from './cronos.svg';
export * as bobaExplorerImg from './boba.svg';
export * as metisExplorerImg from './metis.svg';
export * as auroraExplorerImg from './aurora.svg';
export * as harmonyExplorerImg from './harmony.svg';
export * as moonbeamExplorerImg from './moonbeam.svg';
export * as moonriverExplorerImg from './moonriver.svg';
export * as dogeExplorerImg from './dogechain.svg';
export * as baseExplorerImg from './basescan.svg';
export * as scrollExplorerImg from './scroll.svg';
export * as lineaExplorerImg from './linea.svg';
